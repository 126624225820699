<template>
  <div>
    <layout-header :active-menu="activeMenu"/>
    <div class="m_layout">
      <div class="m-content-box">
        <router-view>
        </router-view>
      </div>
    </div>
    <div class="p_layout">
      <layout-sidebar :collapse="collapse" :is-active-menu="activeMenu"/>
      <div class="p-content-box" :class="{ 'content-collapse': collapse }">
        <div class="content">
          <RightView></RightView>
          <router-view></router-view>
        </div>
      </div>
    </div>

    <div class="vip_mask" v-if="vip_show" >
      <div class="vip_mask_content" :class="{'vip_mask_content_p':!isMobile,'vip_mask_content_m':isMobile}">
        <img src="../assets/images/vip_bg_3.png" style="width: 100%;height: auto" v-if="userInfo.register_for_members_num==3">
        <img src="../assets/images/vip_bg_7.png" style="width: 100%;height: auto" v-if="userInfo.register_for_members_num==7">
        <img src="../assets/images/vip_btn.png" class="vip_btn" @click="toDown">
        <img src="../assets/images/vip_close.png" class="vip_close" @click="vip_show=false"></div>
    </div>

<!--    <el-dialog title="系统公告" :visible.sync="gg_show" :width="isMobile?'300px':'800px'">-->
<!--      <div :class="{'ggBox_p':!isMobile,'ggBox_m':isMobile}">-->
<!--        {{userInfo.system_notice}}-->
<!--      </div>-->
<!--      <div slot="footer">-->
<!--&lt;!&ndash;        <el-button size="small" @click="gg_show=false">取消</el-button>&ndash;&gt;-->
<!--        <el-button size="small" type="primary" @click="gg_show=false">确定</el-button>-->
<!--      </div>-->
<!--    </el-dialog>-->
<!--    <div class="vip_mask" v-if="gg_show" >-->
<!--      <div class="vip_mask_content" :class="{'gg_mask_content_p':!isMobile,'gg_mask_content_m':isMobile}">-->
<!--        <img src="../assets/images/mark.png" style="width: 100%;height: auto">-->
<!--        <img src="../assets/images/gg_close.png" class="vip_close" @click="gg_show=false">-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>
<script>


import LayoutHeader from "../components/LayoutHeader.vue";
import LayoutSidebar from "../components/LayoutSidebar.vue";
import RightView from "../components/RightView.vue";
import {dateFormat} from "@/utils/common";
import Cookies from "js-cookie";


export default {
  components: {LayoutHeader, LayoutSidebar, RightView},
  data() {
    return {
      isMobile: false,
      activeMenu: "",
      onRoutes: "",
      vip_show: false,
      gg_show:false
    }
  },
  computed: {
    tagsList() {
      return this.$store.state.tagsList
    },
    collapse() {
      return this.$store.state.collapse
    },
    userInfo() {
      return this.$store.state.userInfo
    },
  },
  watch: {
    $route(to) {
      this.activeMenu = this.$route.meta.activePath ? this.$route.meta.activePath : this.$route.fullPath
      const isExist = this.tagsList.some((item) => {
        return item.path === to.fullPath;
      });
      if (!isExist) {
        if (this.tagsList.length >= 8) {
          this.$store.commit("delTagsItem", {index: 0});
        }
        this.$store.commit("setTagsItem", {
          name: to.name,
          title: to.meta.title,
          path: to.fullPath,
        });
      }
    },
    userInfo(val) {
      if (val) {
        console.log("用户：", val)
        this.getVip()
        if(this.userInfo.system_notice){
          this.gg_show = true
        }
      }
    },

  },
  methods: {
    getVip() {
      var today = dateFormat('YYYY-mm-dd', new Date())
      var create_time = this.userInfo.create_time ? this.userInfo.create_time.split(" ")[0] : ""
      if (today == create_time) {
        var vipShowTime = sessionStorage.getItem('vipShowTime') ? sessionStorage.getItem('vipShowTime') : 0
        // var vipShowTime = Cookies.get('vipShowTime')?Cookies.get('vipShowTime'):0
        var now = new Date().getTime()
        var diff = now - vipShowTime
        if (diff>600000||vipShowTime==0) {
          if(this.userInfo.register_for_members_num==3||this.userInfo.register_for_members_num==7){
            this.vip_show = true
            sessionStorage.setItem('vipShowTime', now)
          }
        }
    /*    if(!vipShowTime){
          this.vip_show = true
          var date = new Date()
          date.setTime(now+180000)
          console.log("date:",date)
          Cookies.set('vipShowTime',1,{expries:date})
        }*/

      }
    },
    toDown() {
      window.location.href = this.userInfo.download_address
    },
  },
  mounted() {
    if (document.documentElement.clientWidth <= 750) {
      this.isMobile = true
    }
    this.activeMenu = this.$route.meta.activePath ? this.$route.meta.activePath : this.$route.fullPath
    this.getVip()
  }

};
</script>
<style lang="less">
.p-content-box {
  position: absolute;
  left: 176px;
  right: 0;
  top: 70px;
  bottom: 0;
  padding-bottom: 30px;
  -webkit-transition: left .3s ease-in-out;
  transition: left .3s ease-in-out;
  background: #f0f0f0;

  &.content-collapse {
    left: 65px !important;
  }

  .content {
    width: auto;
    height: 100%;
    padding: 15px;
    overflow-y: scroll;
    box-sizing: border-box;
  }
}

.m-content-box {
  position: absolute;
  left: 0px;
  right: 0;
  top: 6.4rem;
  bottom: 0;
  -webkit-transition: left .3s ease-in-out;
  transition: left .3s ease-in-out;
  background: #F6F7FF;
  overflow-x: hidden;
}

.vip_mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;

  .vip_mask_content_p {
    width: 37.7%;
    position: relative;

    .vip_btn {
      position: absolute;
      bottom: 23%;
      width: 60%;
      height: auto;
      left: 17.27%;
    }

    .vip_close {
      position: absolute;
      width: 60px;
      height: 60px;
      top: -8%;
      right: -30px;
    }

  }
  .gg_mask_content_p {
    width: 30%;
    position: relative;

    .vip_btn {
      position: absolute;
      bottom: 23%;
      width: 60%;
      height: auto;
      left: 17.27%;
    }

    .vip_close {
      position: absolute;
      width: 60px;
      height: 60px;
      top: -8%;
      right: -30px;
    }

  }
  .vip_mask_content_m {
    width: 95%;
    position: relative;
    //display: flex;
    text-align: center;

    .vip_btn {
      position: absolute;
      bottom: 32%;
      width: 60%;
      height: auto;
      left: 17.27%;
    }

    .vip_close {
      //position: absolute;
      width: 30px;
      height: 30px;

    }

  }
  .gg_mask_content_m {
    width: 70%;
    position: relative;
    //display: flex;
    text-align: center;

    .vip_btn {
      position: absolute;
      bottom: 32%;
      width: 60%;
      height: auto;
      left: 17.27%;
    }

    .vip_close {
      //position: absolute;
      width: 30px;
      height: 30px;

    }

  }
}

.p_layout{
  .vip_mask_content_p {
    width: 37.7%;
    position: relative;

    .vip_btn {
      position: absolute;
      bottom: 23%;
      width: 60%;
      height: auto;
      left: 17.27%;
    }

    .vip_close {
      position: absolute;
      width: 60px;
      height: 60px;
      top: -8%;
      right: -30px;
    }

  }
}
.m_layout{
  .vip_mask_content_m {
    width: 95%;
    position: relative;
    //display: flex;
    text-align: center;

    .vip_btn {
      position: absolute;
      bottom: 32%;
      width: 60%;
      height: auto;
      left: 17.27%;
    }

    .vip_close {
      //position: absolute;
      width: 30px;
      height: 30px;

    }

  }
}
.ggBox_p{
  overflow: auto;
  max-height: 400px;
}
.ggBox_m{
  overflow: auto;
  max-height: 250px;
}
</style>
