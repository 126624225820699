import Vue from 'vue'
import Vuex from 'vuex'
import request from "@/utils/request";
// 后台没有配置的路由。
import other from '../router/other'
import router from "@/router";
import da from "element-ui/src/locale/lang/da";
import fa from "element-ui/src/locale/lang/fa";
import Cookies from 'js-cookie'

Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        userInfo:{},
        tagsList: [],//历史菜单
        collapse: false,//是否收缩菜单
        isMobileCss:false,//是否手机样式（width<=750）
        handNum:{},//待处理数量
        //菜单
        menuList: [
            {
                title: '首页',
                id: '1',
                icon: 'home_icon',
                front_menu: '/home'
            },
            {
                title: '产品服务',
                id: '2',
                icon: 'service_icon',
                sub: [
                    // {
                    //     title: '订购服务',
                    //     id: '2-1',
                    //     front_menu: '/orderService'
                    // },
                    // {
                    //     title: '我的店铺',
                    //     id: '2-2',
                    //     front_menu: '/myShop'
                    // },
                    {
                        title: '积分充值',
                        id: '2-3',
                        front_menu: '/rechargeIntegral'
                    },
                ]
            },
      /*      {
                title: '个人中心',
                id: '3',
                icon: 'my_icon',
                sub: [
                    // {
                    //   title: '资料设置',
                    //   id: '3-1',
                    //   front_menu: '/userSet'
                    // },
                  /!*  {
                        title: '升级代理',
                        id: '3-2',
                        front_menu: '/upgradeAgent'
                    },*!/
                    {
                        title: '邀请用户',
                        id: '3-3',
                        front_menu: '/inviteUsers'
                    },
                ]
            },*/
    /*        {
                title: '资金管理',
                id: '4',
                icon: 'finance_icon',
                sub: [
                    {
                        title: '资金明细',
                        id: '4-1',
                        front_menu: '/moneyDetail'
                    },
                    {
                        title: '账号充值',
                        id: '4-2',
                        front_menu: '/accountRecharge'
                    }
                ]
            },*/
   /*         {
                title: '收益管理',
                id: '5',
                icon: 'income_icon',
                sub: [
                    {
                        title: '收益明细',
                        id: '5-1',
                        front_menu: '/incomeDetailed'
                    },
                    {
                        title: '收益提现',
                        id: '5-2',
                        front_menu: '/withdraw'
                    }
                ]
            },*/
            {
                title: '订单管理',
                id: '6',
                icon: 'order_icon',
                sub: [
                    // {
                    //     title: '订购记录',
                    //     id: '6-1',
                    //     front_menu: '/orderRecord'
                    // },
                    {
                        title: '充值记录',
                        id: '6-2',
                        front_menu: '/rechargeRecord'
                    },
                /*
                    {
                        title: '提现记录',
                        id: '6-3',
                        front_menu: '/withdrawRecord'
                    },*/
               /*     {
                        title: '邀请用户订购记录',
                        id: '6-4',
                        front_menu: '/inviteOrder'
                    },*/
                ]
            },
          //   {
          //       title: '提现管理',
          //       id: '7',
          //       icon: 'finance_icon',
          //       sub: [
          // /*          {
          //               title: '提现',
          //               id: '6-5',
          //               front_menu: '/withdraw'
          //           },*/
          //           {
          //               title: '提现记录',
          //               id: '6-3',
          //               front_menu: '/withdrawRecord'
          //           }
          //       ]
          //   },
            {
                title: '公告管理',
                id: '8',
                icon: 'income_icon',
                sub: [
                    {
                        title: '公告列表',
                        id: '8-1',
                        front_menu: '/noticeList'
                    }
                ]
            },
            // {
            //     title: '营销课程',
            //     id: '9',
            //     icon: 'income_icon',
            //     sub: [
            //         {
            //             title: '课程列表',
            //             id: '9-1',
            //             front_menu: '/courseList'
            //         }
            //     ]
            // },
        ],
    },
    mutations: {
        delTagsItem(state, data) {
            state.tagsList.splice(data.index, 1);
        },
        setTagsItem(state, data) {
            state.tagsList.push(data)
        },
        clearTags(state) {
            state.tagsList = []
        },
        closeTagsOther(state, data) {
            state.tagsList = data;
        },
        HANDNUM(state,o){
            state.handNum = o
        },
        closeCurrentTag(state, data) {
            for (let i = 0, len = state.tagsList.length; i < len; i++) {
                const item = state.tagsList[i];
                if (item.path === data.$route.fullPath) {
                    if (i < len - 1) {
                        data.$router.push(state.tagsList[i + 1].path);
                    } else if (i > 0) {
                        data.$router.push(state.tagsList[i - 1].path);
                    } else {
                        data.$router.push("/");
                    }
                    state.tagsList.splice(i, 1);
                    break;
                }
            }
        },
        // 侧边栏折叠
        handleCollapse(state, data) {
            state.collapse = data;
        },

        MENULIST(state, data) {
            state.menuList = data
        },
        USERINFO(state, data){
            state.userInfo = data
        },
        ISMOBILECSS(state, data){
            state.isMobileCss = data
        }
    },
    actions: {
        async updataUserInfo({commit}) {
            await request({
                url: 'member/info/myInfo',
                method: "get"
            }).then(res => {
                if (res.code == 200) {
                    commit('USERINFO', res.data)
                }
            })
        },
        async changeHandNum({commit},o){
            if(o){
                commit('HANDNUM',o)
            }else{
                var token = Cookies.get('ad_TOKEN')
                if (token) {
                    var handNum={}
                    await request({
                        url: 'member/notice/noticeUnreadNum',
                        method: "get"
                    }).then(res => {
                        if (res.code == 200) {
                            handNum['公告管理'] = res.data.unreadNum
                            handNum['公告列表'] = res.data.unreadNum
                            commit('HANDNUM',handNum)
                        }
                    })
                }
            }

        },

    },
    getters: {
        handNum(state){
            return state.handNum
        }
    },
    modules: {}
})


