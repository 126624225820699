import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import ElementUI from 'element-ui'
import Vant from 'vant'
import 'element-ui/lib/theme-chalk/index.css'
import 'vant/lib/index.css'
import Cookies from "js-cookie";


import './assets/style/variables.less'

import YtInputNumber from "@/components/YtInputNumber";

import request from "@/utils/request";
import {baseImageUrl} from "@/utils/common";

Vue.use(ElementUI)
Vue.use(Vant)
Vue.prototype.$http = request;
Vue.prototype.$baseImageUrl = baseImageUrl

Vue.config.productionTip = false


Vue.component("YtInputNumber", YtInputNumber);

const TOKEN = () => {
    return Cookies.get('mb_TOKEN')
};
if (TOKEN()) {
    // store.dispatch('fetchMenuList')
 store.dispatch('updataUserInfo')
}
// 判断是否需要登录权限 以及是否登录
router.beforeEach((to, from, next) => {
    if(to.query){
        if(to.query.token){
            Cookies.set('mb_TOKEN', to.query.token)
        }
    }
    // 判断该路由是否需要登录权限
    if (to.path == "/login" || to.path == "/register"|| to.path == "/forgetPass" || to.path == "/login/orderService") {
        next();
    } else {
        let Authorization = TOKEN();
        if (Authorization) {
            next();
        } else {
            next({
                path: "/login",
                // 将跳转的路由path作为参数，登录成功后跳转到该路由
                query: {redirect: to.fullPath}
            });
        }
    }

});

// 跳转后返回顶部
router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);
});


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
