<template>
  <div class="sidebar">
    <!--    background-color="#324157" text-color="#bfcbd9" active-text-color="#20a0ff"-->
    <el-menu class="sidebar-el-menu" :default-active="isActiveMenu" :collapse="collapse" router>
      <template v-for="item in menuList">
        <template v-if="item.sub">
          <el-submenu :index="item.id+''" :key="item.id">
            <template #title>
              <i class="sidebar_icon" :class="item.icon"></i>
              <span>{{ item.title }}<span v-if="handNum[item.title]&&handNum[item.title]>0" style="color: red;">({{ handNum[item.title] }})</span></span>
            </template>
            <template v-for="subItem in item.sub">
              <el-submenu v-if="subItem.sub" :index="subItem.id+''" :key="subItem.id">
                <template #title>{{ subItem.title }}</template>
                <el-menu-item v-for="(threeItem, i) in subItem.sub" :key="i" :index="threeItem.id">
                  {{ threeItem.title }}
                </el-menu-item>
              </el-submenu>
              <el-menu-item v-else :index="subItem.front_menu" :key="subItem.id">{{ subItem.title }}<span v-if="handNum[subItem.title]&&handNum[subItem.title]>0" style="color: red;">({{ handNum[subItem.title] }})</span>
              </el-menu-item>
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item :index="item.front_menu" :key="item.id">
            <i class="sidebar_icon" :class="item.icon"></i>
            <template #title>{{ item.title }}</template>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>

export default {
  props: ['collapse', 'isActiveMenu'],
  computed: {
     menuList(){
       return this.$store.state.menuList
     },
    handNum() {
      return this.$store.state.handNum
    },
  },
  data() {
    return {
      onRoutes: "",
      workDownTime: "",
    }
  },

  methods: {},
  mounted() {
    console.log(this.handNum,555)
    this.onRoutes = this.$route.path
    clearInterval(this.workDownTime)
    this.$store.dispatch('changeHandNum')
    this.workDownTime = setInterval(() => {
      this.$store.dispatch('changeHandNum')
    }, 60*1000)
  }

};
</script>

<style scoped lang="less">
.sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 90px;
  bottom: 0;
  overflow-y: scroll;

  /deep/ .el-menu-item {
    height: 42px;
    line-height: 42px;
    margin-top: 10px;
  }
  /deep/ .el-submenu__title {
    height: 42px;
    line-height: 42px;
    margin-top: 10px;
  }

  /deep/ .el-submenu {
    .el-menu-item {
      margin: 8px 0 6px 42px;
      height: 24px;
      line-height: 24px;
      padding-left: 8px !important;
      min-width: 150px;
    }

    .el-menu-item {
      color: rgb(191,191,191);
    }

    &.is-active {
      .el-submenu__title {
        background: rgb(246,115,95);
        color: #ffffff;

        i {
          color: #ffffff;
        }
      }
    }

    .el-menu-item.is-active {
      background: rgba(246,115,95, 0.2);
      color: rgb(246,115,95);
      border-radius: 4px 0 0 4px;
    }
  }

  .sidebar_icon {
    margin-right: 10px;
  }

  .home_icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    background: url("../assets/images/sidebar/home_icon1.png") no-repeat;
    background-size: 100%;
  }

  .service_icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    background: url("../assets/images/sidebar/service_icon1.png") no-repeat;
    background-size: 100%;
  }

  .finance_icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    background: url("../assets/images/sidebar/finance_icon1.png") no-repeat;
    background-size: 100%;
  }

  .order_icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    background: url("../assets/images/sidebar/order_iocn1.png") no-repeat;
    background-size: 100%;
  }

  .income_icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    background: url("../assets/images/sidebar/income_icon1.png") no-repeat;
    background-size: 100%;
  }

  .my_icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    background: url("../assets/images/sidebar/my_icon1.png") no-repeat;
    background-size: 100%;
  }

  li {
    &.is-active {
      .home_icon {
        width: 20px;
        height: 20px;
        display: inline-block;
        background: url("../assets/images/sidebar/home_icon.png") no-repeat;
        background-size: 100%;
      }

      .service_icon {
        width: 20px;
        height: 20px;
        display: inline-block;
        background: url("../assets/images/sidebar/service_icon.png") no-repeat;
        background-size: 100%;
      }

      .finance_icon {
        width: 20px;
        height: 20px;
        display: inline-block;
        background: url("../assets/images/sidebar/finance_icon.png") no-repeat;
        background-size: 100%;
      }

      .order_icon {
        width: 20px;
        height: 20px;
        display: inline-block;
        background: url("../assets/images/sidebar/order_icon.png") no-repeat;
        background-size: 100%;
      }

      .income_icon {
        width: 20px;
        height: 20px;
        display: inline-block;
        background: url("../assets/images/sidebar/income_icon.png") no-repeat;
        background-size: 100%;
      }

      .my_icon {
        width: 20px;
        height: 20px;
        display: inline-block;
        background: url("../assets/images/sidebar/my_icon.png") no-repeat;
        background-size: 100%;
      }
    }
  }
}

.sidebar::-webkit-scrollbar {
  width: 0;
}

.sidebar-el-menu:not(.el-menu--collapse) {
  width: 176px;
}

.sidebar > ul {
  height: 100%;
}
</style>
